import React from "react";
import styled from "styled-components/macro";
import { Box, CircularProgress } from "@mui/material";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
`;
interface dataInterface {
  padding?: number;
}
function Loader({ padding = 0 }: dataInterface) {
  return (
    <Root>
      <Box padding={padding}>
        <CircularProgress color="secondary" />
      </Box>
    </Root>
  );
}

export default Loader;
