import React from "react";
import Dashboard from "./pages/dashboard/Dashboard";
import BusinessSearchPage from "./pages/businessSearch/BusinessSearchPage";
import SettingsPage from "./pages/settings/SettingsPage";
import BusinessDetailsPage from "./pages/details/BusinessDetailsPage";

// Define a type for the route object
type Route = {
  path: string;
  element: any;
};

// Define the route configuration
const route: Route[] = [
  {
    path: "/",
    element: <Dashboard />
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  },
  {
    path: "/business-search",
    element: <BusinessSearchPage />
  },
  {
    path: "/settings/:businessId/:merchantId",
    element: <SettingsPage />
  },
  {
    path: "/business-details/:businessId",
    element: <BusinessDetailsPage />
  }
];

export default route;
