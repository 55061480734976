export const firebaseConfig = {
  apiKey: "AIzaSyCMBfVn2BkbPl6dE1NCz28YPoc4ZrCSh-s",
  authDomain: "rewardle-integration.firebaseapp.com",
  databaseURL: "https://rewardle-integration.firebaseio.com",
  projectId: "rewardle-integration",
  storageBucket: "rewardle-integration.appspot.com",
  messagingSenderId: "769656637017",
  appId: "1:769656637017:web:c368d3be6f9701217ed348",
  measurementId: "G-TG2TJWSDZR"
};

export const firebaseConfigForProduction = {
  apiKey: "AIzaSyBfU55nTb58NlsLIzb-8UooU4gXf2Fw3qA",
  authDomain: "rewardle-production.firebaseapp.com",
  databaseURL: "https://rewardle-production.firebaseio.com",
  projectId: "rewardle-production",
  storageBucket: "rewardle-production.appspot.com",
  messagingSenderId: "154294863657",
  appId: "1:154294863657:web:4f6d175be477b7bc701938",
  measurementId: "G-QJBPBYV9DC"
};
