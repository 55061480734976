import { useNavigate } from "react-router-dom";
import { Businesssearch } from "@rewardle/apps.ui.businesssearch.businesssearch";
import CopyableText from "../../components/copyableText/CopyableText";
import SettingIcon from "../../assets/icons/settings.png";
import ContractIcon from "../../assets/icons/contract.png";
import DeviceIcon from "../../assets/icons/smartphone-call.png";

const apiEnpoint: any = process.env.REACT_APP_API_BASE_URL;

const BusinessSearchPage = () => {
  const accessToken: any = localStorage?.getItem("accessToken");

  const navigate = useNavigate();

  const searchBusinessColumns: any = [
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 200,
      renderCell: (params: any) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <img
            src={SettingIcon}
            alt="Settings"
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `/settings/${params.row.BusinessId}/${params.row.MerchantId}`,
                "_blank"
              );
            }}
          />
          <img
            src={ContractIcon}
            alt="Contract"
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_BillingPlatform_Portal}/business-contract/${params.row.BusinessId}`,
                "_blank"
              );
            }}
          />
          <img
            src={DeviceIcon}
            alt="Device"
            style={{ cursor: "pointer", width: "20px", height: "20px" }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                `${process.env.REACT_APP_BuisnessDevices_Frontend}/businessdevices/${params.row.BusinessId}`,
                "_blank"
              );
            }}
          />
        </div>
      )
    },
    {
      field: "BusinessName",
      headerName: "BusinessName",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 400,
      renderCell: (params: any) =>
        params?.row?.BusinessName ? params?.row?.BusinessName : "-"
    },
    {
      field: "BusinessId",
      headerName: "BusinessId",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 140,
      renderCell: (params: any) => (
        <div>
          {params?.row?.BusinessId ? (
            <CopyableText text={params?.row?.BusinessId} />
          ) : (
            "-"
          )}
        </div>
      )
    },
    {
      field: "MerchantId",
      headerName: "MerchantId",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 140,
      renderCell: (params: any) => (
        <div>
          {params?.row?.MerchantId ? (
            <CopyableText text={params?.row?.MerchantId} />
          ) : (
            "-"
          )}
        </div>
      )
    },
    {
      field: "Postcode",
      headerName: "Postcode",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 170,
      renderCell: (params: any) =>
        params?.row?.Address?.PostCode ? params?.row?.Address?.PostCode : "-"
    },

    {
      field: "State",
      headerName: "State",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 170,
      renderCell: (params: any) =>
        params?.row?.Address?.State ? params?.row?.Address?.State : "-"
    },
    {
      field: "AdminEmail",
      headerName: "AdminEmail",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 300,
      renderCell: (params: any) => (
        <div>{params?.row?.AdminEmail ? params?.row?.AdminEmail : "-"}</div>
      )
    },
    {
      field: "LoginEmail",
      headerName: "LoginEmail",
      headerClassName: "customTableHeader",
      cellClassName: "customTableCell",
      width: 300,
      renderCell: (params: any) =>
        params?.row?.LoginEmail ? params?.row?.LoginEmail : "-"
    }
  ];

  return (
    <>
      <Businesssearch
        searchBusinessColumns={searchBusinessColumns}
        accessToken={accessToken}
        apiBaseUrl={apiEnpoint}
        handleRowClick={(params: any) =>
          navigate(`/business-details/${params.row.BusinessId}`)
        }
      />
    </>
  );
};

export default BusinessSearchPage;
