import { Grid } from "@mui/material";
import CardContainer from "../../components/dashboardCards/CardContainer";
import {
  businessPortals,
  commingSoonPortals,
  patronsPortals,
  supportPortals
} from "../../utils/commonConstants";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";

const Dashboard = () => {
  console.log("Dashboard");
  return (
    <>
      {/* Support Portal Links */}
      <Breadcrumbs heading="Support Portal Links" subHeading="" docs={null} />

      <Grid container spacing={2}>
        {supportPortals?.map((item: any) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <CardContainer
                Name={item?.Name}
                Link={item?.Link}
                SubTitle={item?.SubTitle}
              />
            </Grid>
          );
        })}
      </Grid>

      {/* Business Portal Links */}
      <div style={{ marginTop: "20px" }}>
        <Breadcrumbs
          heading="Business Portal Links"
          subHeading=""
          docs={null}
        />
      </div>

      <Grid container spacing={2}>
        {businessPortals?.map((item: any) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <CardContainer
                Name={item?.Name}
                Link={item?.Link}
                SubTitle={item?.SubTitle}
              />
            </Grid>
          );
        })}
      </Grid>

      {/* Patrons Portal Links */}
      <div style={{ marginTop: "20px" }}>
        <Breadcrumbs heading="Patrons Portal Links" subHeading="" docs={null} />
      </div>

      <Grid container spacing={2}>
        {patronsPortals?.map((item: any) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <CardContainer
                Name={item?.Name}
                Link={item?.Link}
                SubTitle={item?.SubTitle}
              />
            </Grid>
          );
        })}
      </Grid>

      {/* Coming Soon Portals */}
      <div style={{ marginTop: "20px" }}>
        <Breadcrumbs heading="Coming Soon" subHeading="" docs={null} />
      </div>

      <Grid container spacing={2}>
        {commingSoonPortals?.map((item: any) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <CardContainer
                Name={item?.Name}
                Link={item?.Link}
                SubTitle={item?.SubTitle}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Dashboard;
