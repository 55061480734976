import React from "react";
import { Pagetitlecontainer } from "@rewardle/apps.ui.pagetitlecontainer.pagetitlecontainer";
import { spacing, SpacingProps } from "@mui/system";
import { Pagetitle } from "@rewardle/apps.ui.pagetitlecontainer.pagetitle";
import { Button as MuiButton } from "@mui/material";
import styled from "@emotion/styled";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}
const Button = styled(MuiButton)<ButtonProps>(spacing);
interface BreadcrumbsProps {
  heading: any;
  subHeading: any;
  docs: any;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  heading = "",
  subHeading = "",
  docs = null
}) => {
  return (
    <Pagetitlecontainer>
      <Pagetitle heading={heading} subHeading={subHeading} docs={docs} />
    </Pagetitlecontainer>
  );
};

export default Breadcrumbs;
