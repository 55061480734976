import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import CustomButton from "../../components/CustomButton/Button";
import styled from "styled-components/macro";
import CommonPageLayout from "../../components/commonPageLayout/commonPageLayout";
import {
  Tabs as MuiTabs,
  Tab,
  Box,
  Paper,
  Typography,
  TextField as MuiTextField,
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Alert,
  CircularProgress,
  Tooltip
} from "@mui/material";
import axios from "axios";
import { settingsCategories } from "../../utils/commonConstants";
import {
  GET_BUSINESS_SETTINGS,
  UPDATE_BUSINESS_SETTINGS
} from "../../utils/url";
import Loader from "../../components/loader/Loader";
import CommonButton from "../../components/CommonButton/CommonButton";
import DefaultIcon from "../../assets/icons/default.jpg";

const TextField = styled(MuiTextField)`
  display: flex;
  padding-top: 1px;
  justify-content: center;
  align-items: center;
  label {
    color: var(--light-text-secondary, rgba(0, 0, 0, 0.6));
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.15px;
  }

  input {
    color: var(--light-text-secondary, rgba(0, 0, 0, 0.6));
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    &::before {
      height: 1px;
      background: rgba(0, 0, 0, 0.42);
    }
    &:hover:not(.Mui-disabled)::before {
      height: 1px;
      background: rgba(0, 0, 0, 0.42);
    }
  }
`;

const Tabs = styled(MuiTabs)`
  span {
    background-color: #192f67;
  }
  button {
    color: var(--light-text-secondary, rgba(0, 0, 0, 0.6));
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }
  .Mui-selected {
    color: var(--light-primary-main, #192f67);
  }
`;

const SettingContainer = styled(Paper)`
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

interface Setting {
  SettingName: string;
  Description: string;
  DefaultValue: string;
  Merchant_Overwrite_Value: string | null;
  Merchant_Overwrite_Value_Old: string | null;
  Version?: string;
  Type?: "text" | "number" | "boolean" | "select" | "email" | "profile_builder";
  Options?: string[];
  MinValue?: number;
  MaxValue?: number;
}

interface UpdatedSetting {
  SettingName: string;
  SettingValue: string;
}

interface ProfileBuilderConfig {
  profilescreen: string;
}

const StyledCheckbox = styled(MuiCheckbox)`
  &.Mui-checked {
    color: #192f67;
  }
  &.MuiCheckbox-root {
    color: #192f67;
  }
`;

const SettingsPage = () => {
  const { businessId, merchantId } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [settings, setSettings] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [updatedSettings, setUpdatedSettings] = useState<UpdatedSetting[]>([]);
  const [notificationMessage, setNotificationMessage] = useState({
    message: "",
    type: ""
  });
  console.log("🚀 ~ SettingsPage ~ updatedSettings:", updatedSettings);
  const [version, setVersion] = useState<string>("");
  const [profileBuilderConfig, setProfileBuilderConfig] = useState<
    ProfileBuilderConfig[]
  >([]);

  const handleRedirectToBusinessSearchPage = () => {
    localStorage.setItem("isBackToBusinessSearchPage", "true");
    navigate("/business-search");
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setUpdatedSettings([]);
    setSelectedTab(newValue);
    fetchSettings(settingsCategories[newValue]);
  };

  const fetchSettings = async (category: string) => {
    if (!businessId) return;

    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}${GET_BUSINESS_SETTINGS(
          businessId,
          category
        )}`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      setSettings(response.data);

      const versionSetting = response.data.find(
        (s: Setting) => s.SettingName === "VERSION"
      );
      if (versionSetting) {
        setVersion(
          versionSetting.Merchant_Overwrite_Value || versionSetting.DefaultValue
        );
      }
    } catch (err) {
      setError("Failed to fetch settings");
      setNotificationMessage({
        message: "Failed to fetch settings",
        type: "error"
      });
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      console.error("Error fetching settings:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSettingChange = (
    settingName: string,
    newValue: string | boolean,
    existingIndex: number,
    name: string
  ) => {
    const stringValue =
      typeof newValue === "boolean" ? newValue.toString() : newValue || null;

    // First update the settings state
    setSettings((prev: any) => {
      const newSettings = [...prev];
      newSettings[existingIndex] = {
        ...newSettings[existingIndex],
        [name]: stringValue
      };
      return newSettings;
    });

    // Then update the updatedSettings state
    setUpdatedSettings((prev: any) => {
      const existingSettingIndex = prev.findIndex(
        (setting: any) => setting.SettingName === settingName
      );

      if (existingSettingIndex >= 0) {
        const newSettings = [...prev];
        newSettings[existingSettingIndex] = {
          SettingName: settingName,
          SettingValue: stringValue
        };
        return newSettings;
      }

      return [
        ...prev,
        {
          SettingName: settingName,
          SettingValue: stringValue
        }
      ];
    });
  };

  const handleSaveSettings = async () => {
    if (!merchantId || !businessId || updatedSettings.length === 0) return;

    setSaveLoading(true);
    try {
      const data = {
        MerchantId: merchantId,
        Settings: updatedSettings
      };

      await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}${UPDATE_BUSINESS_SETTINGS(
          businessId
        )}`,
        data,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json"
          }
        }
      );

      // Clear updated settings and refresh current category
      setUpdatedSettings([]);
      setNotificationMessage({
        message: "Settings updated successfully",
        type: "success"
      });
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      fetchSettings(settingsCategories[selectedTab]);
    } catch (err) {
      console.error("Error updating settings:", err);
      setError("Failed to update settings");
      setNotificationMessage({
        message: "Failed to update settings",
        type: "error"
      });
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const handleProfileBuilderChange = (
    setting: Setting,
    index: number,
    checked: boolean,
    screenType: string
  ) => {
    let newConfig: ProfileBuilderConfig[] = [...profileBuilderConfig];

    if (checked) {
      // Add the screen type if not already present
      if (!newConfig.some((config) => config.profilescreen === screenType)) {
        newConfig.push({ profilescreen: screenType });
      }

      // Handle mutual exclusivity rules
      switch (screenType) {
        case "E": // Email
          newConfig = newConfig.filter(
            (config) =>
              !["A", "AM", "EP", "EMP", "EFSP"].includes(config.profilescreen)
          );
          break;
        case "N": // Firstname and Surname
          newConfig = newConfig.filter(
            (config) => !["A", "AM", "EFSP"].includes(config.profilescreen)
          );
          break;
        case "D": // Date of Birth
          newConfig = newConfig.filter(
            (config) => !["A", "AM"].includes(config.profilescreen)
          );
          break;
        case "A": // All fields on one screen
          newConfig = newConfig.filter(
            (config) => !["E", "N", "D"].includes(config.profilescreen)
          );
          break;
        case "AM": // Email, Firstname and Surname, Mobile, Date of Birth
          newConfig = newConfig.filter(
            (config) => !["E", "N", "M", "EMP"].includes(config.profilescreen)
          );
          break;
        case "M": // Mobile
          newConfig = newConfig.filter(
            (config) => !["AM", "EMP"].includes(config.profilescreen)
          );
          break;
        case "PC": // Postcode
          newConfig = newConfig.filter(
            (config) => !["EFSP", "EMP", "EP"].includes(config.profilescreen)
          );
          break;
        case "EP": // Email, Postcode
          newConfig = newConfig.filter(
            (config) =>
              !["E", "PC", "EFSP", "EMP"].includes(config.profilescreen)
          );
          break;
        case "EFSP": // Email, Firstname and Surname, Postcode
          newConfig = newConfig.filter(
            (config) =>
              !["E", "PC", "N", "EP", "EMP"].includes(config.profilescreen)
          );
          break;
        case "EMP": // Email, Mobile, Postcode
          newConfig = newConfig.filter(
            (config) =>
              !["E", "M", "PC", "EP", "EFSP"].includes(config.profilescreen)
          );
          break;
      }
    } else {
      // Remove the screen type if present
      newConfig = newConfig.filter(
        (config) => config.profilescreen !== screenType
      );
    }

    // Sort the config based on the order: E, N, D, M, PC
    const order = ["E", "N", "D", "M", "PC"];
    newConfig.sort(
      (a, b) => order.indexOf(a.profilescreen) - order.indexOf(b.profilescreen)
    );

    setProfileBuilderConfig(newConfig);

    // Update the setting value
    const stringValue = JSON.stringify(newConfig);
    handleSettingChange(
      setting.SettingName,
      stringValue,
      index,
      "Merchant_Overwrite_Value"
    );
  };

  const renderProfileBuilderInput = (setting: Setting, index: number) => {
    if (setting.SettingName !== "PROFILE_BUILDER_CONFIG") return null;

    const value = setting.Merchant_Overwrite_Value || setting.DefaultValue;
    let config: ProfileBuilderConfig[] = [];
    try {
      config = JSON.parse(value);
    } catch (e) {
      console.error("Error parsing profile builder config:", e);
    }

    // Initialize profileBuilderConfig state if empty
    if (profileBuilderConfig.length === 0 && config.length > 0) {
      setProfileBuilderConfig(config);
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "E")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "E"
                )
              }
            />
          }
          label="Email"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "N")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "N"
                )
              }
            />
          }
          label="Firstname and Surname"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "D")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "D"
                )
              }
            />
          }
          label="Date of Birth"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "M")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "M"
                )
              }
            />
          }
          label="Mobile"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "PC")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "PC"
                )
              }
            />
          }
          label="Postcode"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "A")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "A"
                )
              }
            />
          }
          label="All fields on one screen"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "AM")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "AM"
                )
              }
            />
          }
          label="Email, Firstname and Surname, Mobile, Date of Birth"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "EP")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "EP"
                )
              }
            />
          }
          label="Email, Postcode"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "EFSP")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "EFSP"
                )
              }
            />
          }
          label="Email, Firstname and Surname, Postcode"
        />
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={config.some((c) => c.profilescreen === "EMP")}
              onChange={(e) =>
                handleProfileBuilderChange(
                  setting,
                  index,
                  e.target.checked,
                  "EMP"
                )
              }
            />
          }
          label="Email, Mobile, Postcode"
        />
      </Box>
    );
  };

  const renderSettingInput = (setting: Setting, index: number) => {
    const value = setting.Merchant_Overwrite_Value || setting.DefaultValue;
    let name = setting.Merchant_Overwrite_Value
      ? "Merchant_Overwrite_Value"
      : "DefaultValue";

    // Handle number type settings
    const isValidNumber = () => {
      const numericValue: any = !value || value === "" ? "" : Number(value);
      const isNumber = !isNaN(numericValue) && value !== "";
      return isNumber;
    };

    if (value === "true" || value === "false") {
      return (
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={value === "true"}
              onChange={(e) =>
                handleSettingChange(
                  setting.SettingName,
                  e.target.checked,
                  index,
                  name
                )
              }
            />
          }
          label=""
        />
      );
    } else if (isValidNumber()) {
      return (
        <TextField
          id={setting.SettingName}
          label=""
          type="number"
          variant="standard"
          size="small"
          value={value}
          onChange={(e: any) => {
            const newValue = e.target.value;
            // Allow empty string or valid numbers
            handleSettingChange(setting.SettingName, newValue, index, name);
          }}
        />
      );
    } else {
      return (
        <TextField
          id={setting.SettingName}
          label=""
          type="text"
          variant="standard"
          fullWidth
          value={value}
          onChange={(e: any) =>
            handleSettingChange(
              setting.SettingName,
              e.target.value,
              index,
              name
            )
          }
        />
      );
    }
  };

  useEffect(() => {
    fetchSettings(settingsCategories[0]);
  }, [businessId]);

  return (
    <CommonPageLayout
      heading="Business Settings"
      subHeading={`Manage Business Settings`}
      docs={
        <CustomButton callBack={handleRedirectToBusinessSearchPage}>
          {"Back"}
        </CustomButton>
      }
    >
      <Paper elevation={0} style={{ padding: "20px" }}>
        {notificationMessage.message && (
          <Alert
            severity={notificationMessage.type as any}
            sx={{ mb: 2 }}
            onClose={() => setNotificationMessage({ message: "", type: "" })}
          >
            {notificationMessage.message}
          </Alert>
        )}

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {settingsCategories.map((category, index) => (
              <Tab
                key={category}
                label={category.charAt(0).toUpperCase() + category.slice(1)}
                id={`settings-tab-${index}`}
              />
            ))}
          </Tabs>
        </Box>

        {settingsCategories.map((category, index) => (
          <TabPanel key={category} value={selectedTab} index={index}>
            {loading ? (
              <Loader />
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {settings?.map((setting: any, index: any) => {
                  const value =
                    setting.Merchant_Overwrite_Value || setting.DefaultValue;
                  return (
                    <SettingContainer key={setting.SettingName}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {(value === "true" || value === "false") &&
                          renderSettingInput(setting, index)}
                        <Typography variant="h6">
                          {setting.SettingName?.split("_")?.join(" ")}
                        </Typography>
                        {!setting.Merchant_Overwrite_Value && (
                          <Tooltip title="Default Value">
                            <img
                              src={DefaultIcon}
                              alt="DefaultValue"
                              style={{
                                cursor: "pointer",
                                width: "25px",
                                height: "25px"
                              }}
                            />
                          </Tooltip>
                        )}
                      </Box>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {setting.Description}
                      </Typography>

                      {value !== "true" && value !== "false" && (
                        <Box
                          sx={{ display: "flex", gap: 2, alignItems: "center" }}
                        >
                          {renderSettingInput(setting, index)}
                        </Box>
                      )}
                    </SettingContainer>
                  );
                })}

                <Box sx={{ mt: 2, display: "flex" }}>
                  <CommonButton
                    onClick={() =>
                      updatedSettings?.length > 0
                        ? handleSaveSettings()
                        : () => {}
                    }
                    disabled={saveLoading || updatedSettings?.length <= 0}
                    variant="contained"
                  >
                    {saveLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </CommonButton>
                </Box>
              </Box>
            )}
          </TabPanel>
        ))}
      </Paper>
    </CommonPageLayout>
  );
};

export default SettingsPage;
