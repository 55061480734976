export const supportPortals = [
  {
    Name: "",
    Link: process.env.REACT_APP_RewardlePay_Potal,
    SubTitle: "RewardlePay"
  },
  {
    Name: "",
    Link: process.env.REACT_APP_Payments_Admin_Potal,
    SubTitle: "PaymentAdmin"
  },

  {
    Name: "",
    Link: process.env.REACT_APP_Users_Potal,
    SubTitle: "Users"
  },

  {
    Name: "",
    Link: process.env.REACT_APP_Ribbons_Potal,
    SubTitle: "Ribbons"
  },
  {
    Name: "",
    Link: process.env.REACT_APP_Prepaid_Potal,
    SubTitle: "Prepaid"
  },
  {
    Name: "",
    Link: process.env.REACT_APP_CardPanels_Frontend,
    SubTitle: "CardPanels"
  },
  {
    Name: "",
    Link: process.env.REACT_APP_PromoCodesPanels_Frontend,
    SubTitle: "PromoCodes"
  },
  {
    Name: "",
    Link: process.env.REACT_APP_BuisnessDevices_Frontend,
    SubTitle: "BuisnessDevices"
  },
  {
    Name: "",
    Link: process.env.REACT_APP_Liability_Portal,
    SubTitle: "Liability"
  },
  {
    Name: "",
    Link: process.env.REACT_APP_BillingPlatform_Portal,
    SubTitle: "BillingPlatform"
  }
];

export const businessPortals = [
  {
    Name: "",
    Link: process.env.REACT_APP_Payments_Potal,
    SubTitle: "Payments"
  }
];

export const patronsPortals = [
  {
    Name: "",
    Link: process.env.REACT_APP_Patrons_Portal,
    SubTitle: "Patrons"
  }
];

export const commingSoonPortals = [
  {
    Name: "",
    Link: process.env.REACT_APP_Tags_Potal,
    SubTitle: "Tags"
  }
];

export const settingsCategories = ["rewards", "checkin", "sharing", "prepaid"];
