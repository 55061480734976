import React from "react";
import styled from "styled-components/macro";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

interface StyledButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}

const StyledButtonBase = styled(MuiButton)<StyledButtonProps>`
  &.blue_button {
    background-color: #1976d2;
    color: white;
    &:hover {
      background-color: #1565c0;
    }
  }

  &.custom_disabled {
    background-color: #ccc !important;
    cursor: not-allowed !important;
    opacity: 0.7;
    pointer-events: all !important;
  }
`;

interface CommonButtonProps extends MuiButtonProps {
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  type?: "button" | "submit" | "reset";
  fullWidth?: boolean;
  children: React.ReactNode;
}

const CommonButton: React.FC<CommonButtonProps> = ({
  onClick,
  isLoading = false,
  disabled = false,
  className = "blue_button",
  variant = "contained",
  color = "primary",
  type = "button",
  fullWidth = false,
  children,
  ...rest
}) => {
  const isDisabled = disabled || isLoading;
  const buttonClassName = `${className} ${
    isDisabled ? "custom_disabled" : ""
  }`.trim();

  return (
    <StyledButtonBase
      onClick={onClick}
      disabled={isDisabled}
      className={buttonClassName}
      variant={variant}
      color={color}
      type={type}
      fullWidth={fullWidth}
      {...rest}
    >
      {children}
    </StyledButtonBase>
  );
};

export default CommonButton;
