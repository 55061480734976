import React from "react";
import { Papercontainer } from "@rewardle/apps.ui.papercontainer.papercontainer";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";

interface CommonPageLayoutProps {
  heading?: string;
  subHeading?: string;
  docs?: any;
  children: any;
}
const CommonPageLayout: React.FC<CommonPageLayoutProps> = ({
  heading = "",
  subHeading = "",
  docs = "",
  children
}) => {
  return (
    <React.Fragment>
      <Breadcrumbs heading={heading} subHeading={subHeading} docs={docs} />
      <Papercontainer elevation={0}>{children}</Papercontainer>
    </React.Fragment>
  );
};

export default CommonPageLayout;
