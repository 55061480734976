import React, { Component, ReactNode } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error:", error);
    console.error("Error Info:", errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <span
          style={{
            color: "#192F67"
          }}
        >
          Something went wrong. Please try again later.
        </span>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
