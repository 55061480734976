import axios from "axios";

export function GET({
  url,
  params = {},
  headers = { "Content-Type": "application/json" }
}) {
  return axios
    .get(url, {
      headers: headers,
      params: params,
      referrerPolicy: "origin-when-cross-origin",
      cache: "no-cache",
      mode: "cors"
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error ? error : "Something went wrong");
    });
}

export function POST({ url, body = {}, params = {} }) {
  return axios
    .post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer "
      },
      params
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error ? error : "Something went wrong");
    });
}
