import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GET } from "../../utils/Method";
import { GET_ACTIVE_SUPPORT_USERS } from "../../utils/url";
import { RootState } from "../store";
const apiEnpoint = process.env.REACT_APP_API_BASE_URL;
export interface UsersManagementState {
  apiState: "idle" | "fetching" | "failed";
  Response: any;
  payloadSize: number;
  pageNumber: number;
  pageData: Array<number>;
  pageLastEvaluatedKey: any;
  totalCount: number;
}

const initialState: UsersManagementState = {
  apiState: "idle",
  Response: [
    {
      data: [],
      page: 0,
      lastEvaluatedKey: null
    }
  ],
  payloadSize: 10,
  pageNumber: 0,
  pageData: [],
  pageLastEvaluatedKey: null,
  totalCount: 0
};

export const getInitialUserManagementData = createAsyncThunk(
  "userManagement/userManagementListData",
  async (
    {
      accessToken = "",
      payloadSize = 10,
      lastEvaluatedKey = null,
      page = 0,
      response = null
    }: any,
    thunkAPI
  ) => {
    try {
      let existingRecord =
        response !== null ? response.find((x: any) => x.page === page) : null;
      if (
        page !== 0 &&
        existingRecord !== undefined &&
        existingRecord !== null &&
        existingRecord.data !== null &&
        existingRecord.data.length > 0
      ) {
        let existingPageData = {
          currentData: existingRecord.data,
          currentLastEvaluatedKey: existingRecord.lastEvaluatedKey
        };
        thunkAPI.dispatch(setPayloadSize(payloadSize));
        thunkAPI.dispatch(setPage(page));
        thunkAPI.dispatch(setPageData(existingPageData));
        return;
      } else {
        console.log(accessToken);
        let headers = {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json"
        };
        let params = {
          size: payloadSize,
          LastEvaluatedKey: undefined
        };
        if (lastEvaluatedKey !== null && page !== 0)
          params.LastEvaluatedKey = lastEvaluatedKey;

        let result: any = await GET({
          url: apiEnpoint + GET_ACTIVE_SUPPORT_USERS,
          params: {},
          headers: headers
        });
        console.log("🚀 ~ file: usersManagement.ts:80 ~ result:", result);
        let resultData: any = {
          Items: result
        };
        if (resultData && resultData.Items) {
          thunkAPI.dispatch(setPayloadSize(payloadSize));
          thunkAPI.dispatch(setPage(page));
          thunkAPI.dispatch(setResponse(resultData));
          return result;
        }
      }
    } catch (error) {
      return Promise.reject(error ? error : "Something went wrong");
    }
  }
);

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setResponse: (state, action: PayloadAction<any>) => {
      const listArray: any = Object.keys(action.payload.Items).map(
        (i) => action.payload.Items[i]
      );
      let resultData = {
        data: listArray,
        lastEvaluatedKey: action.payload.LastEvaluatedKey,
        page: state.pageNumber
      };
      let count = state.totalCount;
      if (state.pageNumber === 0) {
        state.Response = [resultData];
        count = listArray.length;
      } else {
        state.Response.push(resultData);
        count = count + listArray.length;
      }
      state.totalCount = count;
      state.pageData = listArray;
      state.pageLastEvaluatedKey =
        action.payload.LastEvaluatedKey !== undefined
          ? action.payload.LastEvaluatedKey
          : null;
    },
    setPageData: (state, action: PayloadAction<any>) => {
      state.pageData = action.payload.currentData;
      state.pageLastEvaluatedKey = action.payload.currentLastEvaluatedKey;
    },
    setPayloadSize: (state, action: PayloadAction<number>) => {
      state.payloadSize = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInitialUserManagementData.pending, (state) => {
        state.apiState = "fetching";
      })
      .addCase(getInitialUserManagementData.fulfilled, (state, action) => {
        state.apiState = "idle";
      })
      .addCase(getInitialUserManagementData.rejected, (state) => {
        state.apiState = "failed";
      });
  }
});

export default userManagementSlice.reducer;

export const { setPayloadSize, setPage, setPageData, setResponse } =
  userManagementSlice.actions;

export const getList = (state: RootState) => state?.userManagement?.pageData;
export const getAPIStatus = (state: RootState) =>
  state?.userManagement?.apiState;

export const getResponse = (state: RootState) =>
  state?.userManagement?.Response;

export const getLastEvaluatedKey = (state: RootState) =>
  state?.userManagement?.pageLastEvaluatedKey;

export const getPayloadSize = (state: RootState) => {
  return state?.userManagement?.payloadSize;
};

export const getCurrentPage = (state: RootState) => {
  return state?.userManagement?.pageNumber;
};

export const getTotalCount = (state: RootState) => {
  return state?.userManagement?.totalCount;
};
