import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CommonPageLayout from "../../components/commonPageLayout/commonPageLayout";
import CommonButton from "../../components/CommonButton/CommonButton";
import { VpnKey, OpenInNew, Edit } from "@mui/icons-material";
import Loader from "../../components/loader/Loader";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  CircularProgress,
  TextField,
  Alert
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  BUSINESS_DETAILS,
  BUSINESS_UNLOCK,
  UPDATE_BUSINESS_LOGIN
} from "../../utils/url";

const VpnKeyIcon = VpnKey as any;
const EditIcon = Edit as any;
const apiEndpoint = process.env.REACT_APP_API_BASE_URL;

// Add email validation regex
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

interface BusinessDetails {
  BusinessName: string;
  ResetPasswordTokenUrl: string;
  LoginEmail: string;
  IsLockedOut: number;
  MerchantLoginTokenUrl: string;
}

const BusinessDetailsPage = () => {
  const { businessId }: any = useParams();
  const navigate = useNavigate();
  const [businessDetails, setBusinessDetails] =
    useState<BusinessDetails | null>(null);
  const [status, setStatus] = useState<
    "idle" | "fetching" | "success" | "error"
  >("idle");
  const [openUnlockModal, setOpenUnlockModal] = useState(false);
  const [unlockLoading, setUnlockLoading] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [newLoginEmail, setNewLoginEmail] = useState("");
  const [emailError, setEmailError] = useState<string>("");
  const [notificationMessage, setNotificationMessage] = useState({
    message: "",
    type: ""
  });

  // Add email validation function
  const validateEmail = (email: string) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!EMAIL_REGEX.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const fetchBusinessDetails = async () => {
    if (businessId) {
      try {
        setStatus("fetching");
        const accessToken = localStorage.getItem("accessToken") || "";
        const response = await axios({
          method: "get",
          url: `${apiEndpoint}${BUSINESS_DETAILS}/${businessId}`,
          headers: {
            Authorization: `bearer ${accessToken}`,
            "Content-Type": "application/json"
          }
        });
        setBusinessDetails(response.data);
        setStatus("success");
      } catch (err: any) {
        setStatus("error");
      }
    }
  };

  useEffect(() => {
    fetchBusinessDetails();
  }, [businessId]);

  const handleRedirectToBusinessSearchPage = () => {
    localStorage.setItem("isBackToBusinessSearchPage", "true");
    navigate("/business-search");
  };

  const handleUnlockBusiness = async () => {
    if (!businessId) return;

    setUnlockLoading(true);
    try {
      const accessToken = localStorage.getItem("accessToken") || "";
      await axios({
        method: "put",
        url: `${apiEndpoint}${BUSINESS_UNLOCK}/${businessId}`,
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json"
        }
      });
      setNotificationMessage({
        message: "Business unlocked successfully",
        type: "success"
      });
      setOpenUnlockModal(false);
      await fetchBusinessDetails();
    } catch (err: any) {
      setNotificationMessage({
        message: err.message || "Failed to unlock business",
        type: "error"
      });
    } finally {
      setUnlockLoading(false);
    }
  };

  const handlePasswordReset = () => {
    if (businessDetails?.ResetPasswordTokenUrl) {
      window.open(businessDetails.ResetPasswordTokenUrl, "_blank");
    }
  };

  const handleMerchantsPortal = () => {
    if (businessDetails?.MerchantLoginTokenUrl) {
      window.open(businessDetails.MerchantLoginTokenUrl, "_blank");
    }
  };

  const handleEditLoginEmail = async () => {
    if (!businessId || !newLoginEmail) return;

    if (!validateEmail(newLoginEmail)) return;

    setEditLoading(true);
    try {
      const accessToken = localStorage.getItem("accessToken") || "";
      await axios({
        method: "put",
        url: `${apiEndpoint}${UPDATE_BUSINESS_LOGIN}/${businessId}`,
        headers: {
          Authorization: `bearer ${accessToken}`,
          "Content-Type": "application/json"
        },
        data: {
          Email: newLoginEmail
        }
      });
      setOpenEditModal(false);
      setNewLoginEmail("");
      setEmailError("");
      await fetchBusinessDetails();
    } catch (err: any) {
      setNotificationMessage({
        message: err.message || "Failed to update login email",
        type: "error"
      });
    } finally {
      setEditLoading(false);
    }
  };

  const handleOpenEditModal = () => {
    setNotificationMessage({
      message: "",
      type: ""
    });
    if (businessDetails) {
      setNewLoginEmail(businessDetails.LoginEmail);
      setEmailError("");
      setOpenEditModal(true);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setNewLoginEmail(email);
    validateEmail(email);
  };

  return (
    <>
      <CommonPageLayout
        heading={
          businessDetails?.BusinessName ? businessDetails?.BusinessName : "-"
        }
        subHeading={`Manage Business`}
        docs={
          <CommonButton onClick={handleRedirectToBusinessSearchPage}>
            Back
          </CommonButton>
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignContent: "center",
            justifyContent: "end"
          }}
        >
          <RefreshIcon
            onClick={async () => await fetchBusinessDetails()}
            sx={{ cursor: "pointer" }}
          />
        </Box>

        <div style={{ padding: "20px" }}>
          {status === "fetching" ? (
            <Loader />
          ) : status === "error" ? (
            <div>{"No Data Available"}</div>
          ) : (
            businessDetails && (
              <>
                <div style={{ marginBottom: "20px" }}>
                  <h3>Account Status</h3>
                  <div style={{ marginTop: "10px" }}>
                    <span
                      style={{
                        color: businessDetails?.IsLockedOut ? "red" : "green",
                        marginRight: "10px",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }}
                    >
                      {businessDetails?.IsLockedOut ? "Locked" : "Not Locked"}
                    </span>
                    {businessDetails?.IsLockedOut > 0 && (
                      <CommonButton
                        onClick={() => {
                          setNotificationMessage({
                            message: "",
                            type: ""
                          });
                          setOpenUnlockModal(true);
                        }}
                      >
                        Unlock
                      </CommonButton>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <h3>Account credentials</h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px"
                    }}
                  >
                    <span>{businessDetails?.LoginEmail}</span>
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleOpenEditModal}
                      title="Edit Login Email"
                    />
                    <VpnKeyIcon
                      style={{ cursor: "pointer" }}
                      onClick={handlePasswordReset}
                      title="Change Password"
                    />
                  </div>
                </div>

                <div>
                  <Box>
                    <CommonButton onClick={handleMerchantsPortal}>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px"
                        }}
                      >
                        Merchants Portal <OpenInNew />
                      </span>
                    </CommonButton>
                  </Box>
                </div>
              </>
            )
          )}
        </div>
      </CommonPageLayout>

      {/* Unlock Confirmation Modal */}
      <Dialog
        open={openUnlockModal}
        onClose={() => !unlockLoading && setOpenUnlockModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: 1.25,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                color: "#192F67"
              }}
            >
              Confirm Unlock
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => !unlockLoading && setOpenUnlockModal(false)}
              disabled={unlockLoading}
              sx={{
                color: (theme) =>
                  unlockLoading
                    ? theme.palette.grey[400]
                    : theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>
            Are you sure you want to unlock this business account? This will
            allow the user to log in again.
          </Typography>
          {notificationMessage?.message && (
            <div style={{ marginTop: "10px" }}>
              <Alert severity={notificationMessage?.type as any}>
                {notificationMessage?.message}
              </Alert>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <CommonButton
            onClick={() => !unlockLoading && setOpenUnlockModal(false)}
            disabled={unlockLoading}
            variant="contained"
          >
            Cancel
          </CommonButton>

          <CommonButton
            onClick={handleUnlockBusiness}
            disabled={unlockLoading}
            isLoading={unlockLoading}
            variant="contained"
            sx={{ minHeight: "36px" }}
          >
            {unlockLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Unlock"
            )}
          </CommonButton>
        </DialogActions>
      </Dialog>

      {/* Edit Login Email Modal */}
      <Dialog
        open={openEditModal}
        onClose={() => !editLoading && setOpenEditModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: 1.25,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                color: "#192F67"
              }}
            >
              Edit Login Email
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => !editLoading && setOpenEditModal(false)}
              disabled={editLoading}
              sx={{
                color: (theme) =>
                  editLoading
                    ? theme.palette.grey[400]
                    : theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            label="Login Email"
            value={newLoginEmail}
            onChange={handleEmailChange}
            disabled={editLoading}
            error={!!emailError}
            helperText={emailError}
            sx={{ mt: 1 }}
          />
          {notificationMessage?.message && (
            <div style={{ marginTop: "10px" }}>
              <Alert severity={notificationMessage?.type as any}>
                {notificationMessage?.message}
              </Alert>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <CommonButton
            onClick={() => !editLoading && setOpenEditModal(false)}
            disabled={editLoading}
            variant="contained"
          >
            Cancel
          </CommonButton>
          <CommonButton
            onClick={handleEditLoginEmail}
            disabled={editLoading || !newLoginEmail || !!emailError}
            isLoading={editLoading}
            variant="contained"
            sx={{ minHeight: "36px" }}
          >
            {editLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Save"
            )}
          </CommonButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BusinessDetailsPage;
