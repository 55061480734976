import { Home, Briefcase } from "react-feather";

export type SidebarItemsType = {
  Link: string;
  Name: string;
  Icon: React.FC<any>;
  children?: SidebarItemsType[];
  badge?: string;
};

var elementsSectionForSupport = [
  {
    Link: "/",
    Icon: Home,
    Name: "Dashboard"
  },
  {
    Link: "/business-search",
    Icon: Briefcase,
    Name: "Business"
  }
] as SidebarItemsType[];

if (process.env.REACT_APP_ENV_NAME === "int") {
}

const TopSection = [] as SidebarItemsType[];

const menuItems = [
  {
    title: "",
    role: "all",
    pages: TopSection
  },
  {
    title: "",
    role: "Support",
    pages: elementsSectionForSupport
  }
];

export default menuItems;
