export const spiltStringAndGetLastIndex = (data: any) => {
  let splitData = data?.toString()?.split("-");
  return splitData[splitData?.length - 1];
};

export const getLastDigitFromUserId = (userId: any) => {
  const userIdString = userId?.toString() || "";
  const parts = userIdString.split("-");
  const lastElement = parts.length > 1 ? parts[parts.length - 1] : userIdString;

  return lastElement;
};
