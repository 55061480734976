import React, { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { getLastDigitFromUserId } from "../../utils/commonFunctions";

interface CopyableTextProps {
  text: string;
  value?: any;
}

const CopyableText: React.FC<CopyableTextProps> = ({ text, value = null }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value ? value : text); // Copy text to clipboard
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "left"
      }}
    >
      <div
        style={{
          verticalAlign: "middle",
          display: "flex",
          alignItems: "center"
        }}
      >
        {" "}
        {text ? getLastDigitFromUserId(text) : "-"}
      </div>
      {text && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleCopy();
          }}
        >
          <Tooltip
            title={copied ? "Copied to clipboard!" : "Copy to clipboard"}
            arrow
            placement="bottom-start"
          >
            <IconButton>
              <ContentCopyIcon
                style={{
                  marginTop: "0px",
                  paddingTop: "0px",
                  width: "0.9rem ",
                  height: "0.9rem "
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default CopyableText;
