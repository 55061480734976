import React from "react";
import styled from "styled-components/macro";
import { Button as MuiButton } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

interface ButtonProps extends SpacingProps {
  component?: React.ElementType;
  to?: string;
  target?: string;
}
const Button: any = styled(MuiButton)<ButtonProps>(spacing);

interface ButtonProps {
  children: any;
  callBack: any;
}
const CustomButton: React.FC<ButtonProps> = ({ children, callBack }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      className="blue_button"
      onClick={callBack}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
