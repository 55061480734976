import { useRoutes, useNavigate } from "react-router-dom";
import { SupportApp } from "@rewardle/apps.supportapp";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import routes from "./routes";
import { firebaseConfig, firebaseConfigForProduction } from "./config";
import menuItems from "./utils/MenuItems";

function App() {
  const content: any = useRoutes(routes);
  const navigate = useNavigate();
  console.log(process.env.REACT_APP_IS_LIVE, "APP_IS_LIVE Or Not");
  const firebaseConfigData =
    process.env.REACT_APP_IS_LIVE === "true"
      ? firebaseConfigForProduction
      : firebaseConfig;

  return (
    <>
      <SupportApp
        firebaseConfig={firebaseConfigData}
        apiDomain={undefined}
        menuItems={menuItems}
        navigate={navigate}
      >
        <Provider store={store}>{content}</Provider>
      </SupportApp>
    </>
  );
}

export default App;
