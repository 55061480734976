import * as React from "react";
import {
  Box as MuiBox,
  Typography as MuiTypography,
  CardActions as MuiCardActions
} from "@mui/material";

import styled from "@emotion/styled";

const CardActions = styled(MuiCardActions)`
  width: 100%;
  display: flex;
  padding: 0;
`;

const Box = styled(MuiBox)`
  display: flex;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: var(--light-background-paper, #fff);
  box-shadow: 0px 4px 12px 0px rgba(191, 191, 191, 0.14);
  cursor: pointer;
`;

const CartTitleTypography = styled(MuiTypography)`
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

const TypographySubTtile = styled(MuiTypography)`
  color: var(--light-text-primary, #192f67);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

interface CardContainerProps {
  Name: string;
  Link: any;
  SubTitle: any;
}

const CardContainer: React.FC<CardContainerProps> = ({
  Name = "",
  Link = null,
  SubTitle = ""
}) => {
  const handleRedirectToThePortal = () => {
    window.open(Link);
  };

  return (
    <Box sx={{ minHeight: "153px" }} onClick={handleRedirectToThePortal}>
      <CardActions>
        <CartTitleTypography>{Name}</CartTitleTypography>
        {SubTitle && <TypographySubTtile>{SubTitle}</TypographySubTtile>}
      </CardActions>
    </Box>
  );
};

export default CardContainer;
